

@import './utils/butterfly-anim';
@import './utils/variables';
@import './utils/mixins';
@import './components/Navigation';
@import './components/header';
@import './components/footer';
@import './components/partners';
@import './components/identification';
@import './components/button';
@import './components/presentation-admin';
@import './components/createUser';
@import './components/user';
@import './components/authForm';
@import './components/modifPassword';
@import './components/article';
@import './components/library';
@import './layout/level';
@import './components/adminList';
@import './components/dashboard';
@import './components/staffList';
@import './layout/carrousel';
@import './layout/messageError';
@import './layout/member';
@import './layout/button1';
@import './pages/Home.scss';
@import './pages/Join.scss';
@import './pages/articlesList.scss';


*{
    box-sizing: border-box;
    font-family: 'Mulish', sans-serif;
    margin: 0;
}
h1{
    color:$color-quaternary;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    text-align: center;
}
h2{
    margin: 0;
    color: $color-quaternary;
    font-size: 20px;
    text-align: center;
    font-family: 'Oswald', sans-serif;
}

h3{
    color:$color-quaternary;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    border-bottom: 1px double $color-quaternary;
    width: 100%;
}
h4{
    color:$color-quaternary;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    text-align: center;
}

.bg-img{
    background: url('../assets/image-accueil.jpg') center/cover;
    position: relative;
    text-decoration: none;
    width: 100vw;
    height: 100vh;
    a{
        text-decoration: none;
    }
    .citation{
        color: $color-quaternary;
        text-align: center;
        padding-top: 5%;
        font-size: 20px;
        font-weight: bold;
        margin: 0;
        padding-top: 30px;
    }
};
.partner{
    position: absolute;
    top: calc(100% - 100px);
    width: 75%;
    height: 100px;
    display:flex;
    justify-content: space-around;
}



@media screen and (max-width: 900px){
    .partner{
       display: none;
    }
}
.inputToDelete{
    display:none;
}

