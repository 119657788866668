.footer{
    background-color: rgb(240, 240, 240);
    width: 100%;    
    padding: 0 15px;
   &__content{
       display: flex;
       justify-content: space-between;
       align-items: center;
       color: black;
       font-size: 12px;
       p{
           margin-top: 20px;
       }
       a{
           color: black;
           text-decoration: none;
           border-bottom: 1px solid rgb(240, 240, 240);
           &:hover{
               font-weight: bold;
               border-bottom: 1px solid black;
           }
       }
    }
}
@media screen and (max-width: 900px){
    .footer{
        margin-top: 30px;
    }
    .footer__content{
        flex-direction: column;
        align-items: flex-start;
    }
}
