.button1{
    background-color: rgba(255, 255, 255, 0.5);
    border: $color-quaternary 1px solid;
    padding:3px 10px;
    border-radius:5px;
    color:$color-quaternary;
    border:$color-quaternary 1px soild;
    &:hover{
        font-weight: bold;
        background-color: rgba(255, 255, 255, 0.751);
        cursor: pointer;
    }
    a{
        text-decoration: none;
        color: $color-quaternary;
    }
}
@media screen and (max-width: 900px){
    .button1{
        width: 80%;
        max-width: 400px;
        min-width: 250px;
        margin: auto;
    }
}