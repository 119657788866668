
  .mySwiper {
    width:80%;
    height: 550px;
  }
  .swiper-button-next, .swiper-button-prev{
      color: grey;
  }
  .article{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    max-width: 480px;
    margin: auto;
    height:550px;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 50px;
      &__content{ 
          display: flex;
          align-items: center;
          width: 100%;
          padding:2%;
      
          &__image{
          width: 50%;
          height:80%; 
          overflow: hidden;
          img{
              width: 100%;
              height: 100%;
              object-fit: cover;
              } 
          }
          &__title{
              font-family: 'Oswald', sans-serif;
              color: $color-quaternary;
              font-weight: bold;
              margin: 10px auto;
              font-size: 25px;
              text-align: center;
          }  
        }
          &__text{
              color: $color-quaternary;
              font-family: 'Mulish', sans-serif;
              margin-top: 20px;
              font-size: 14px;
              padding: 10px;
              p{
                  margin-bottom: 7px;
              }
          }
  }
  
  @media screen and (max-width: 900px){
      .mySwiper{
          width: 100%;
      }
      .swiper-button-next, .swiper-button-prev{
          top: 22%;
      }
      .article{
          width: 100%;
          border-radius: 0;
          &__content{
              flex-direction: column;
          }
      }
  }
.carousselBox{
    display:flex;
    width:100%;
    justify-content:space-between;
}
.carousselImg{
    width: 50%;
    object-fit: cover;
}
.carousselInfos{
    width:50%;
}