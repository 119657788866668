.usersList{
    display:flex;
    flex-direction:column;
   
}

.input-control{
    display:flex;
    align-items: center;
    max-width: 450px;
    background-color: #f4f4f4;
    border-radius: 6px;
    padding:15px;
    input{
        width: 100vw;
        padding-left: 10px;
        outline: 0;
        border: 0;
        color: black;
        background-color:#f4f4f4;


    }
}
.input-control input::placeholder{
    color: #838f9f;
}
.backDashboard{
    &:hover{
        cursor: pointer;
    }
    img{
        width: 25px;
        padding-right: 3px;
    }
}


.users{
    display:flex;
    flex-direction: column;
    width: 100%;
    max-width: 1500px;
    
}
.intro-users{
    display: flex;
    justify-content: space-between;
}

.profil{
    &__element{
        margin-bottom: 5px;
        &__title{
            font-weight: bold;
        }
    }

}
.usersButtons{
    display:flex;
    flex-direction: column;
    
    &__buttons{
        width: 100%;
        margin-bottom: 10px;;
    }
}
.adminRights-name{
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 20px;
}
.adminRights-button{
    display: flex;
    padding: 30px 0;
    justify-content: space-between;
}
.userToAdmin{
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    &__user{
        display:flex;
        align-items: center;
        gap: 10px;
        padding: 20px 0;
        border-bottom: 1px black solid;
        button{
            background-color: white;
            padding: 5px 20px;
            border:1px rgb(226, 226, 226) solid;
            color:rgb(132, 132, 132);
            &:hover{
                border:#1890ff 1px solid;
            }
        }
    }
}
.adminRights-head{
    display: flex;
    align-items: center;
    justify-content:space-between;
    margin: 20px 0 ;

}
.update-profil{
    margin: 20px 50px;
}
.update-profil-btn{
    display: flex;
    margin: 20px;
    gap: 50px;
}
.update-profil-edit{
    width:50%;
    margin: 50px auto;
}
.update-profil-title{
    margin-top: 50px;
}
@media screen and (max-width: 900px){
    .update-profil-btn{
        display: flex;
        flex-direction: column;
        margin: 20px;

    }
}
