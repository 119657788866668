.dashboard{
    width:80%;
    margin: 100px auto;
    display:flex;
    justify-content: space-around;
    flex-wrap: wrap;
    &__bloc{
        display:flex;
        width: calc(33% - 30px);
        height: 300px;
        padding:20px;
        min-width: 300px;
        margin-bottom: 50px;
        border-radius: 10px;
       div{
        display:flex;
        justify-content: space-between;
        flex-direction: column;
        margin: 0 auto;
        width: 100%;
       }
       &--users{
        background-color: #a8d58a50;
       }
       &--staff{
        background-color: #c8d8e875;
       }
       &--books{
        background-color: #f8c1b875;
       }
       &--articles{
        background-color: #dfc8ef75;
       }
    }
}
.backDashboard{
    text-align: end;
    a{
        text-decoration: none;
        color:$color-quaternary;
    }
}
.dashboardMain{
    margin-top: 50px;
}
@media screen and (max-width: 900px){

.dashboard{
    width: 100%;
    margin:50px auto;
    &__bloc{
        width: 100%;
        border-radius:0;
        padding:10px;
        text-align: center;
        height: 200px;
       
    }
}    
}