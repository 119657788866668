.anim{
    
    position: absolute;
    transform: translateX(0) translateY(0);
    opacity: 0;
    &--red{
        z-index: 2;
        animation: cross 5000ms linear;  
        top: random(1) * 50%;
        right: random(10) * 50px;
    }
    &--yellow{
        z-index: 4;
        bottom: random(1) * 50%;
        left: random(20) * 25px;
        animation: crossNegatif 7000ms linear ;
        animation-delay: 4000ms;
    }
    &--pink{
        z-index: 2;
        bottom: random(1) * 30%;
        left: random(30) * 5px;
        animation: crossNegatif 6000ms linear ;
        animation-delay: 9000ms;
    }
    &--blue{
        z-index: 2;
        bottom: random(1) * 50%;
        left: random(10) * 50px;
        animation: crossNegatif 5250ms linear ;
        animation-delay: 7000ms;
    }
}
  
.butterfly{  
    &--1{
        position: absolute;
        opacity: 1;
        animation: butterfly1 400ms linear;
        animation-iteration-count: infinite;
    }
    &--2{
        position: absolute;
        opacity: 0;
        animation: butterfly2 400ms linear;
        animation-iteration-count: infinite;
    }
}
@keyframes butterfly1{
    0%{
        opacity: 1;
    }
    50%{
        opacity:0;
    }
    100%{
        opacity: 1;
    };
};
@keyframes butterfly2{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    };
};
@keyframes cross{
    0%{
         transform: translateX(0) translateY(0);
         opacity: 1;
    }
    10%{
        transform: translateX(-100px) translateY(-20px);
    }
    30%{
        transform: translateX(-300px) translateY(10px);
    }
   50%{
        transform: translateX(-500px) translateY(-30px);
    }
    70%{
        transform: translateX(-600px) translateY(-90px);
        opacity: 1;
    } 
    100%{
        transform: translateX(-750px) translateY(-110px);
        opacity: 0;
    }
}
@keyframes crossNegatif{
    0%{
         transform: translateX(0) translateY(0);
         opacity: 1;
    }
    10%{
        transform: translateX(100px) translateY(-20px);
    }
    30%{
        transform: translateX(300px) translateY(10px);
    }
   50%{
        transform: translateX(500px) translateY(-30px);
    }
    70%{
        transform: translateX(600px) translateY(-90px);
        opacity: 1;
    } 
    100%{
        transform: translateX(750px) translateY(-110px);
        opacity: 0;
    }
}
@media screen and (max-width: 900px){
  

    @keyframes cross{
        0%{
             transform: translateX(0) translateY(0);
             opacity: 1;
        }
        10%{
            transform: translateX(-30px) translateY(-20px);
        }
        30%{
            transform: translateX(-50px) translateY(10px);
        }
       50%{
            transform: translateX(-70px) translateY(-30px);
        }
        70%{
            transform: translateX(-100px) translateY(-90px);
            opacity: 1;
        } 
        100%{
            transform: translateX(-150px) translateY(-110px);
            opacity: 0;
        }
    }
    @keyframes crossNegatif{
        0%{
             transform: translateX(0) translateY(0);
             opacity: 1;
        }
        10%{
            transform: translateX(30px) translateY(-20px);
        }
        30%{
            transform: translateX(50px) translateY(10px);
        }
       50%{
            transform: translateX(70px) translateY(-30px);
        }
        70%{
            transform: translateX(100px) translateY(-90px);
            opacity: 1;
        } 
        100%{
            transform: translateX(150px) translateY(-110px);
            opacity: 0;
        }
    } 
}