
.header{
    display:flex;
    justify-content: space-around;
    width: 100%;
    &--color{
        display: flex;
        flex-direction: column;
        align-items: center;
        @include background-header;
    }
    &__head{
        display:flex;
        justify-content: space-around;
        align-items: center;
        width:100%;
        margin-bottom: 30px;
        &__title{
            display: flex;
            justify-content: space-around;
            width: 100%;
            &--color{
                margin-top: -35px;
                margin-left: 100px;
            }
        }
        &__nav{
            &__logo{
                height:130px;
            }
        }
    }
}
.home-icone{
    width:24px;
    height:24px;
}
.navbar{
    width: 100%;
    background-color: none;
}


@media screen and (max-width: 900px){
   
    h1{
        font-size: 20px;
        width: 100%;
    }
    .header{
      
        &__title{
            &--color{
                margin: 20px 0 0 0;
           
            }
        }
    }
   
}
