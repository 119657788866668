@import '../utils/variables';
@import '../utils/mixins';

.libraryPage{
    display: flex;
    padding: 50px 0 20px 0;
}

.libraryNav{
    display: flex;
    flex-direction: column;
    width: 20vw;
    border-right: 1px solid $color-quaternary;
    &_liste{
        display: flex;
        flex-direction: column;
    }
}


.library{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 80vw;
    margin:0 50px;
}

.allBooks{
display: flex;
justify-content: center;
flex-wrap: wrap;
margin-top: 50px;
}

.categorie{
    width: 100%;
}

.mainBook{
    display: flex;
    width: 30%;
    min-width: 450px;
    height: 200px;
    @include box-shadow-normal;
    padding: 10px;
    margin-bottom: 30px;
    &:hover{
        border: 1px rgb(197, 197, 197) solid; 
        box-shadow: 0 5px 9px 2px rgba(0, 0, 0, 0.3), 0 7px 21px 2px rgba(0, 0, 0, 0.29);
        cursor: pointer;
    }
}

.book{
    display:flex;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    &_image{
        width: 40%;
        height:80%; 
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            } 
    }
    &_text{
        display: flex;
        flex-direction: column;
        width: 60%;
        height:80%; 
        position: relative;
        z-index: 1;
        &_info{
            &_title{
            color: $color-tertiary;
            font-family: 'Oswald', sans-serif;
            font-size: 18px;
            &:hover{
                text-decoration: underline;
            }
            }
            &_author{
                font-size: 12px;
            }
        }
        &_star{
            display: flex;   
            align-items: center;  
            font-size: 15px;
            margin-top: 5px;       
            img{
                width: 80%;
                height: 80%;
            }
        }
    }
}


.bookDetail{
    display:flex;
    justify-content: space-around;
    &_visuel{
        width: 20%;
        min-height: 500px;
        margin-top: 100px;
        &_image{
            overflow: hidden;
            margin-bottom: 30px;
            img{
                width: 200px;
                height: 300px;
                object-fit: contain;
                } 
        }
    }
    &_text{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 60%;
        height:80%; 
        position: relative;
        z-index: 1;
        &_info{
            &_title{
            color: $color-tertiary;
            font-family: 'Oswald', sans-serif;
            font-size: 30px;
            &:hover{
                text-decoration: underline;
            }
            }
            &_author{
                font-size: 20px;
            }
        }
        &_resume{
            margin: 60px 0;
            font-size: 20px;

        }
        &_notice{
            display: flex;
            flex-direction: column;
        }
    }
}

.likeMain{
    position: relative;
    top: 60%;
    right: 57%;
    height: 30px;
    width: 30px;
    z-index: 10;
    cursor:pointer;
}

.likeDetail{
    position: absolute;
    z-index: 10;
    left: 7%;
    top : 500px;
    height: 30px;
    width: 30px;
    cursor:pointer;
}

.noticeInfos{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-size: 16px;
    margin-bottom: 10px;
}

.noticeContent{
    font-size: 18px;
}

.yourNotice{
    font-size: 18px;
}

