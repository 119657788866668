
.containerHome{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:auto;
    width:100%; 
    background-image: radial-gradient(circle at 69% 86%, rgba(165, 165, 165,0.06) 0%, rgba(165, 165, 165,0.06) 25%,rgba(193, 193, 193,0.06) 25%, rgba(193, 193, 193,0.06) 50%,rgba(221, 221, 221,0.06) 50%, rgba(221, 221, 221,0.06) 75%,rgba(249, 249, 249,0.06) 75%, rgba(249, 249, 249,0.06) 100%),radial-gradient(circle at 49% 76%, rgba(129, 129, 129,0.06) 0%, rgba(129, 129, 129,0.06) 25%,rgba(164, 164, 164,0.06) 25%, rgba(164, 164, 164,0.06) 50%,rgba(200, 200, 200,0.06) 50%, rgba(200, 200, 200,0.06) 75%,rgba(235, 235, 235,0.06) 75%, rgba(235, 235, 235,0.06) 100%),radial-gradient(circle at 22% 64%, rgba(173, 173, 173,0.06) 0%, rgba(173, 173, 173,0.06) 25%,rgba(119, 119, 119,0.06) 25%, rgba(119, 119, 119,0.06) 50%,rgba(64, 64, 64,0.06) 50%, rgba(64, 64, 64,0.06) 75%,rgba(10, 10, 10,0.06) 75%, rgba(10, 10, 10,0.06) 100%),linear-gradient(307deg, rgb(255,255,255),rgb(255,255,255));
    &__carrousel{
        margin-top:50px;
        width:100%;
    }
    &__map{
        display:flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        margin-bottom: 100px;
    }
}

.testimony{
    display: flex;
    
    justify-content: center;
    align-items: center;
    width:23%;
    margin:100px auto;
    gap: 100px;
}
.events{
    width: 100%;
    margin-top: 50px;
    padding-top: 20px;
    background-color: white;
    &__title{
        color:$color-quaternary;
        font-family: 'Oswald', sans-serif;
        font-weight: bold;
        font-size: 18px;
        text-align: center;
    }
    &__content{
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: 60px auto;
    color: $color-quaternary;
    &__item{
        border-left: 1px dotted $color-quaternary;
        width: 100%;
        max-width: 250px;
        padding-left:30px;
        h4{
            margin-bottom: 7px;
        }
    }
    p{
        font-size: 14px;
        }
    }
}
#map{
    width: 50%;
    height: 300px;
}


@media screen and (max-width: 900px){
    .containerHome{
       &__map{
        flex-direction: column;
       }
    }
   .testimony{
    flex-direction: column-reverse;
    gap:0;
    margin-top: 50px;
    }
    .events{
        margin-top: 50px;
        &__content{
            flex-direction: column; 
            margin-top: 20px;
            &__item{
            margin-bottom: 10px;
            border-bottom: 1px dotted $color-quaternary;
            border-left: none;
            h4{
                margin-bottom: 5px;
            }
        }
        }
       
    }
    #map{
        width:100%;
    }

}