.resetPassword{
    display:flex;
    justify-content: flex-end;
    font-size: 0.9em;
    &:hover{
        cursor: pointer;
    }
}

.initPassword{
    &__form{
        display: flex;
        flex-direction: column;
        max-width: 500px;
        margin: 200px auto;
        &__header{
            display: flex;
            justify-content: space-around;
            padding-bottom: 20px;
            img{
                width: 100px;
            }
        
    }
}}
.login-form-button {
    display: flex;
    margin: auto;
    justify-content: center;
  }